<template>
  <div :style="computedStyle" v-show="!isHidden" :class="CSSClasses" class="boolean_field">
    <el-form-item
      ref="form_item"
      style="margin: 0"
      :prop="name"
      :rules="[
        { validator: requiredValidator, trigger: 'change' },
      ]"
    >
      <el-checkbox v-bind:value="!!stringToBoolean" :size="size" :disabled="_isReadonly"
                   v-on:input="$emit('input', $event)" :name="name">{{ label }}
      </el-checkbox>
      <el-tooltip v-if="tooltip" style="margin-left: 5px" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import RequiredMixin from '@/components/InterfaceEditor/components/required_properties_mixin'

export default {
  name: 'boolean_field',
  mixins: [mixin, registryMixin, RequiredMixin],
  props: {
    tooltip: {
      description: 'tooltip',
      type: String
    },
    label: {
      description: 'name',
      type: String
    }
  },
  computed: {
    stringToBoolean () {
      if (typeof this.value !== 'string') {
        return this.value
      }
      switch (this.value?.toLowerCase()?.trim()) {
        case 'true':
        case 'yes':
        case '1':
          return true

        case 'false':
        case 'no':
        case '0':
          return false

        default:
          return this.value
      }
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  methods: {
    requiredValidator (rule, value, callback) {
      if (value || (value === null || !this._isRequired)) {
        callback()
      } else {
        callback(this.$locale.main.message.required_field)
      }
    }
  }
}
</script>

<style scoped>
.boolean_field /deep/ .el-form-item__content {
  line-height: 0;
}
</style>
